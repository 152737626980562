import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchProjects } from '../../api/projectsApi';


const PortfolioOne = () => {
  const BASE_URL = process.env.REACT_APP_PUBLIC_URL_API;
  let publicUrl = process.env.PUBLIC_URL + '/';
  let imagealt = 'image';

  const [projects, setProjects] = useState([]);

  useEffect(() => {
      // Define an async function inside the effect
      const fetchData = async () => {
          try {
              const response = await fetchProjects();
              console.log(response, "RESSS alll");
              setProjects(response); // Assuming response.data is the array of projects
          } catch (error) {
              console.error("Failed to fetch projects", error);
          }
      };

      // Call the async function
      fetchData();
  }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <section className="portfolio-one go-top">
      <div className="container">
        <div className="block-title text-center">
          <p><span>Our Project</span></p>
          <h3>Let’s See Our Popular <br /> Project</h3>
          <div className="block-title__line" />
        </div>
        <div className="row">
          {/* Portfolio items */}
          {projects?.map((item, index) => (
            <div className="col-lg-4" key={item.id}>
              <div className="portfolio-one__single">
                <img src={`${BASE_URL}${item.thumbnail}`} alt={imagealt} />
                <div className="portfolio-one__content">
                  <div className="portfolio-one__content-inner">
                    <h3><Link to={`/portfolio/${item.slug}`}>{item.title}</Link></h3>
                    <p>Study Guides</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>
        <div className="portfolio-one__more-btn__block text-center">
          <Link to="/portfolio" className="thm-btn portfolio-one__more-btn">View More <i className="fa fa-angle-double-right" /></Link>
        </div>
      </div>
    </section>
  );
}

export default PortfolioOne;
