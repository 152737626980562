import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const PageHeader = ({ headertitle, subheader, Img }) => {
    let publicUrl = process.env.PUBLIC_URL + '/';
    let HeaderTitle = headertitle;
    let Subheader = subheader ? subheader : HeaderTitle;
    let backgroundImage = Img ? Img : 'page-header-bg-1-1.jpg';

    return (
        <section className="page-header" style={{ backgroundImage: `url(${publicUrl}assets/images/backgrounds/${backgroundImage})` }}>
            <div className="container">
                <h2>{HeaderTitle}</h2>
                <ul className="list-unstyled thm-breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li><span>{Subheader}</span></li>
                </ul>
            </div>
        </section>
    );
}

export default PageHeader;