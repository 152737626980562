import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { fetchProjects } from '../../api/projectsApi';

const PortFolioFull = () => {
    const BASE_URL = process.env.REACT_APP_PUBLIC_URL_API;
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        // Define an async function inside the effect
        const fetchData = async () => {
            try {
                const response = await fetchProjects();
                setProjects(response); // Assuming response.data is the array of projects
            } catch (error) {
                console.error("Failed to fetch projects", error);
            }
        };

        // Call the async function
        fetchData();
    }, []); // The empty array ensures this effect runs only once after the initial render


    return (

        <section className="portfolio-three go-top" >
            <div className="container-fluid">
                <div className="block-title-two text-center">
                    <h3>Let’s See Our Most <br /> Recent Work</h3>
                </div>
                <div className="row no-gutters ">
                    {/* Portfolio items */}
                    {projects?.map((item, index) => (
                        <div className={`col-md-${index >= 4 ? '6' : '4'} col-sm-12`} key={index}>
                            <div className="portfolio-three__single">
                                <div className="portfolio-three__image">
                                    <img src={`${BASE_URL}${item.thumbnail}`} alt="#" />
                                </div>
                                <div className="portfolio-three__content">
                                    <div className="portfolio-three__content-inner">
                                        <Link to={`/portfolio/${item.slug}`} className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></Link>
                                        <h3><Link to={`/portfolio/${item.slug}`}>{item.title}</Link></h3>
                                        <p><Link to="/portfolio/portfolio-details">Design.</Link><Link to="/portfolio-details">Branding</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    );
}

export default PortFolioFull;
