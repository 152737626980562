import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class Sidebar extends Component {
  render() {
   // let anchor = '#'
   // let imagealt = 'image'
   // let publicUrl = process.env.PUBLIC_URL+'/'
    return (
      <div className="col-lg-4  go-top">
		        <div className="sidebar">
		          <div className="sidebar__single sidebar__cta" style={{backgroundImage: 'url(assets/images/blog/blog-s-cta-1.jpg)'}}>
		            <h3>Work <br /> Together</h3>
		            <p>Bur wemust ipsum dolor sit amet <br /> consectetur adipisicing elit sed eiusmod <br /> tempor
		              incididunt ut labore</p>
		            <a href="/contact" className="thm-btn sidebar__cta-btn">Contact Now <i className="fa fa-angle-double-right" /></a>{/* /.thm-btn sidebar__cta-btn */}
		          </div>
		        </div>
		      </div>
    )
  }
}

export default Sidebar;
