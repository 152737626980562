import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class AboutStyleTwo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="about-two  go-top">
		  <img src={publicUrl+"assets/images/shapes/about-two-bg.png"} alt={ imagealt } className="about-two__bg" />
		  <div className="container">
		    <img src={publicUrl+"assets/images/resources/cta-2-1.png"} alt={ imagealt } className="about-two__moc" />
		    <div className="row">
		      <div className="col-lg-6">
		        <div className="about-two__content">
		          <div className="block-title text-left">
		            <p><span>What We Do</span></p>
		            <h3>We Offer Best Soluation <br /> For Your Business</h3>
		            <div className="block-title__line" />{/* /.block-title__line */}
		          </div>{/* /.block-title */}
		          <p>In today’s fast-paced market, your business deserves solutions that not only meet current needs but also anticipate future
					  challenges. At our core, we're dedicated to providing innovative and scalable solutions tailored to your unique business objectives.
					  From enhancing operational efficiency to unlocking new growth opportunities,
					  our team is equipped with the expertise and technology to drive your success.
					  Partner with us for solutions that transform challenges into victories.</p>
		          <div className="about-two__box">
		            <div className="about-two__box-icon">
		              <i className="far fa-leaf" />
		            </div>{/* /.about-two__box-icon */}
		            <div className="about-two__box-content">
		              <h3>Manage Your Project</h3>
		              <p>Efficiently manage your projects with our expert guidance and innovative tools.
						  Our approach ensures seamless execution and delivery,
						  focusing on maximizing productivity and meeting your business objectives. Let us help you navigate the
						  complexities of project management with ease.</p>
		            </div>{/* /.about-two__box-content */}
		          </div>{/* /.about-two__box */}
		          <div className="about-two__box">
		            <div className="about-two__box-icon">
		              <i className="far fa-laptop-code" />
		            </div>{/* /.about-two__box-icon */}
		            <div className="about-two__box-content">
		              <h3>Business &amp; Data Analytics</h3>
		              <p>Unlock the power of your data with our Business & Data Analytics services.
						  We provide comprehensive insights into your operations,
						  helping you make informed decisions that drive growth and efficiency.
						  Transform your data into actionable strategies with our expertise.</p>
		            </div>{/* /.about-two__box-content */}
		          </div>{/* /.about-two__box */}
		        </div>{/* /.about-two__content */}
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        }
}

export default AboutStyleTwo