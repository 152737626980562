import React, { useEffect } from 'react';

const Footer_v1 = () => {
    useEffect(() => {
        const $ = window.$;

        let publicUrl = process.env.PUBLIC_URL + '/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/theme.js";

        document.body.appendChild(minscript);

        $('.go-top').find('a').on('click', function () {
            $(window).scrollTop(0);
        });
    }, []);

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imgattr = "Footer logo"
    const myUrlApi = process.env.REACT_APP_PUBLIC_URL_API;

    return (
        <div>
            <footer className="site-footer-one">
                    <div className="site-footer-one__upper">
                        <div className="container">
                            <img src={publicUrl + "assets/images/resources/footer-1-1.png"} alt={myUrlApi} className="site-footer-one__upper-moc" />
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="footer-widget footer-widget__about">
                                        <a href="index-2.html">
                                            <img src={publicUrl + "assets/images/logo-dark.png"} alt={imgattr} />
                                        </a>
                                        <p>Welcome to our website! Here, we're dedicated to providing you with top-notch web development services
                                            tailored to your unique needs.  </p>
                                        <div className="footer-widget__social">
                                            <a href="https://www.facebook.com/VPixelSolutions"><i className="fab fa-facebook-f" /></a>
                                            <a href="#"><i className="fab fa-twitter" /></a>
                                            <a href="#"><i className="fab fa-google-plus-g" /></a>
                                        </div>{/* /.footer-widget__social */}
                                    </div>{/* /.footer-widget */}
                                </div>{/* /.col-lg-3 */}
                                <div className="col-lg-4">
                                    <div className="footer-widget footer-widget__contact">
                                        <h3 className="footer-widget__title">Address</h3>{/* /.footer-widget__title */}
                                        <div className="footer-widget__contact-box">
                                            <div className="footer-widget__contact-top">
                                                <i className="far fa-map-marked" />
                                                <h3>Address </h3>
                                            </div>{/* /.footer-widget__contact-top */}
                                            <p>Vancouver</p>
                                        </div>{/* /.footer-widget__contact-box */}
                                        <div className="footer-widget__contact-box">
                                            {/*<p><a href="tel:+82-123-456-89">+</a></p>*/}
                                        </div>{/* /.footer-widget__contact-box */}
                                    </div>{/* /.footer-widget footer-widget__contact */}
                                </div>{/* /.col-lg-4 */}
                            </div>{/* /.row */}
                        </div>{/* /.container */}
                    </div>{/* /.site-footer-one__upper */}
                    <div className="site-footer-one__bottom">
                        <div className="container">
                            <div className="site-footer-one__bottom-line" />{/* /.site-footer-one__bottom-line */}
                            <p>Copy@2022. All Right Reserved</p>
                        </div>{/* /.container */}
                    </div>{/* /.site-footer-one__bottom */}
                </footer>
        </div>
    );
}

export default Footer_v1;
