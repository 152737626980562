import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {fetchProjectDetails} from '../../api/projectsApi';

const PortfolioDetails = ({ onChildDataChange }) => {
    const { slug } = useParams(); // If you're using slug, replace `id` with `slug`
    const [project, setProject] = useState(null);

    useEffect(() => {
        // Define an async function inside the effect
        const fetchData = async () => {
            try {
                const response = await fetchProjectDetails(slug);
                console.log(response, "RESSS");
                setProject(response); // Assuming response.data is the array of projects
                onChildDataChange(response.title);
            } catch (error) {
                console.error("Failed to fetch projects", error);
            }
        };

        // Call the async function
        fetchData();
    }, [slug]);

    // Placeholder for your environment variable or directly use the string if you don't have a different public URL
    const BASE_URL = process.env.REACT_APP_PUBLIC_URL_API;
    const imageAlt = 'image';

    if (!project) return <div>Loading...</div>; // Loading state or spinner

    return (
        <section className="portfolio-details">
            <div className="container">
                <h3 className="portfolio-details__title">{project.sub_title}</h3>
                <br />
                <p>{project.content}</p>
                <br />
                <div className="my-div-img">
                    <img src={`${BASE_URL}${project.image}`} alt={imageAlt} className="img-fluid" />
                </div>
                <br />
                <br />
                <p>{project.longDescription}</p>
                <br />
                <h4>Technologies used</h4>
                <br />
                <div className="portfolio-details__box">
                    {project.technologies_used.split(", ").map((technology, index) => (
                        <div key={index} className="portfolio-details__box-single">
                            <h3>{technology}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PortfolioDetails;