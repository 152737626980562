import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Comments from './comments';
import Sidebar from './sidebar';
class BlogDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return (
     <section className="blog-list blog-details">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-8">
		        <div className="blog-one__single">
		          <div className="blog-one__image">
		            <img src={publicUrl+"assets/images/blog/blog-s-1-1.jpg"} alt={ imagealt } />
		          </div>{/* /.blog-one__image */}
		          <div className="blog-one__content">
		            <div className="blog-one__top">
		              <div className="blog-one__author">
		                <img src={publicUrl+"assets/images/blog/blog-author-1-1.jpg"} alt={ imagealt } />
		                <p>Hakila K. Dolika</p>
		              </div>{/* /.blog-one__author */}
		              <div className="blog-one__social">
		                <span>Share Now</span>
		                <b><i className="far fa-share-alt" /></b>
		                <a href="https://www.facebook.com/VPixelSolutions"><i className="fab fa-facebook-f" /></a>
		                <a href="#"><i className="fab fa-twitter" /></a>
		                <a href="#"><i className="fab fa-linkedin-in" /></a>
		              </div>{/* /.blog-one__social */}
		            </div>{/* /.blog-one__top */}
		            <h2>Adobe Experience Manager vs. WordPress The Authoring Expeene
		              Compared</h2>
					<section>
						<h3>Introduction</h3>
						<p>Content management systems (CMS) are pivotal in today's digital landscape, with Adobe Experience Manager (AEM) and WordPress leading the charge, catering to different market segments. This article delves into their authoring experiences.</p>
					</section>

					<section>
						<h3>Adobe Experience Manager: An Overview</h3>
						<p><strong>Target Audience and Market Position:</strong> AEM is a comprehensive solution designed for enterprises with complex needs.</p>
						<p><strong>Authoring Experience:</strong> Features rich text editing, complex content structures, content personalization, and multi-channel publishing.</p>
						<p><strong>Pros and Cons:</strong> Robust, scalable, with extensive integration capabilities, though costly and with a steep learning curve.</p>
					</section>

					<section>
						<h3>WordPress: An Overview</h3>
						<p><strong>Target Audience and Market Position:</strong> The most popular CMS, known for its ease of use and extensive support.</p>
						<p><strong>Authoring Experience:</strong> User-friendly with the Gutenberg editor, vast customization options through themes and plugins, and strong SEO capabilities.</p>
						<p><strong>Pros and Cons:</strong> Affordable, customizable, with a large community, though security and complexity can be issues.</p>
					</section>

					<section>
						<h3>Comparing the Authoring Experience</h3>
						<p><strong>User Interface:</strong> Discusses the ease of use, content editing, and learning curve of both platforms.</p>
						<p><strong>Content Management Capabilities:</strong> Comparison of creation, management, and publishing of content, including multimedia support.</p>
						<p><strong>Customization and Flexibility:</strong> The extent of customization through plugins, themes, or features.</p>
						<p><strong>Support and Community:</strong> Available support options, including forums and official resources.</p>
					</section>

					<footer>
						<h2>Conclusion</h2>
						<p>The choice between AEM and WordPress depends on an organization's needs, budget, and expertise. AEM is ideal for enterprise-level functionality, while WordPress offers flexibility for a wide range of projects.</p>
						<p><a href="#/contact">Consult with a web development professional</a> for advice tailored to your project's needs.</p>
					</footer>
		            <blockquote>
		              <p>Keeping Node.js Fast Tools Techniques And Tips For Making High Performance Node Ser Ways
		                Another System Commity.
		                <i className="fal fa-quote-right" />
		              </p>
		            </blockquote>
		            <br />
		          </div>{/* /.blog-one__content */}
		          <p className="blog-details__tags">
		            <i className="far fa-tags" />
		            <span>Popular Tag : </span>
		            <a href="#">Business,</a>
		            <a href="#">SEO,</a>
		            <a href="#">Digital Marketing</a>
		          </p>{/* /.blog-details__tags */}
		          <br />
		        </div>{/* /.blog-one__single */}
		        <div className="blog-post__nav">
		          <div className="blog-post__nav-left">
		            <h3><a href="#">Building Pub Sub Service House Using Node And Redis</a></h3>
		          </div>{/* /.blog-post__nav-left */}
		          <div className="blog-post__nav-right">
		            <h3><a href="#">Once Upon Time Using Story Stre For Better Engagement</a></h3>
		          </div>{/* /.blog-post__nav-right */}
		        </div>{/* /.blog-post__nav */}
		       <Comments />
		      </div>{/* /.col-lg-8 */}
		      <Sidebar />
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

    )
  }
}

export default BlogDetails;
