import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class AboutStyleOne extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="about-one  go-top">
			  <div className="container">
			    <img src={publicUrl+"assets/images/resources/cta-1-1.png"} alt={ imagealt } className="about-one__moc" />
			    <div className="row justify-content-end">
			      <div className="col-lg-6">
			        <div className="about-one__content">
			          <div className="block-title text-left">
			            <p><span>About Us</span></p>
			            <h3>We Are Specialized In <br /> Business Soluation</h3>
			            <div className="block-title__line" />{/* /.block-title__line */}
			          </div>{/* /.block-title */}
			          <p>Our expertise lies in crafting tailored business solutions that drive success and innovation.
						  We understand the challenges businesses face today, and our team is dedicated to providing strategic
						  insights and digital solutions that propel your operations forward. From optimizing processes to
						  leveraging the latest in technology, we're here to help you achieve your business objectives with precision and excellence.</p>
			          <h4>We Are Always With You</h4>
			          <p>At the heart of our mission is a commitment to standing by our clients every step of the way.
						  Whether you're embarking on a new project or seeking to enhance your current digital strategy,
						  our team offers continuous support and expert guidance. We pride ourselves on our collaborative approach,
						  working closely with you to ensure your digital journey is seamless, successful, and aligned with your vision.</p>
			          <Link to="/about" className="thm-btn about-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			          {/* /.thm-btn */}
			        </div>{/* /.about-one__content */}
			      </div>{/* /.col-lg-6 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>

        }
}

export default AboutStyleOne