import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Contact from './section-components/contact-page';
import Footer from './global-components/footer-v2';

export const ContactPage = () => {
    return <div>
      <Helmet>
        <title>VPixelSolutions | Get Expert Web Development Services Today</title>
        <meta name="description" content="Reach out to VPixelSolutions for top-tier web development and design services. Custom solutions, site optimization, and innovative features to elevate your online presence. Contact us now!" />
        {/* meta */}
      </Helmet>
        <Navbar />
        <PageHeader headertitle="Contact Us"  />
        <Contact />
        <Footer />
    </div>
}

