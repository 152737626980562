import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutPage extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return	<section className="service-two service-two__service-page service-two__about-page  go-top">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6">
			        <div className="service-two__image">
			          <img src={publicUrl+"assets/images/resources/about-page-moc-1.png"} alt={ imagealt } />
			        </div>{/* /.service-two__image */}
			      </div>{/* /.col-lg-6 */}
			      <div className="col-lg-6">
			        <div className="service-two__block">
			          <div className="block-title-two text-left">
			            <p>about us</p>
			            <h3>Exclusive Agency To <br /> Provide Soluation</h3>
			          </div>{/* /.block-title-two */}
			          <p>Our commitment to excellence in web development and application creation sets us apart.
						  We specialize in crafting bespoke digital experiences that drive growth, enhance user engagement,
						  and elevate your online presence. From innovative website designs to dynamic web applications,
						  our team delivers solutions that are not only visually stunning but also functionally superior. </p>
			          <ul className="list-unstyled video-one__list">
			            <li>
			              <i className="far fa-check" />
			              Cutting-Edge Web Development
			            </li>
			            <li>
			              <i className="far fa-check" />
			              Innovative Web Applications
			            </li>
			            <li>
			              <i className="far fa-check" />
			              Strategic Digital Solutions
			            </li>
			            <li>
			              <i className="far fa-check" />
			              Exceptional User Experiences
			            </li>
			          </ul>{/* /.list-unstyled video-one__list */}
			          <Link to="/service" className="thm-btn">Read More <i className="fa fa-angle-double-right" /></Link>
			          {/* /.thm-btn */}
			        </div>{/* /.service-two__block */}
			      </div>{/* /.col-lg-5 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>



        }
}

export default AboutPage