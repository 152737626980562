import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomeV1 } from './components/home-v1';
import { AboutPage } from './components/about';
import { ServiceV1 } from './components/service';
import { ServiceDetails } from './components/service-details';
import { PortfolioFull } from './components/portfolio-full';
import { PortfolioDetails } from './components/portfolio-details';
import { BlogList } from './components/blog-list';
import { BlogListDetails } from './components/blog-details'; // Assuming the actual export is BlogDetails
import { ContactPage } from './components/contact';
import { initGA, PageView } from './analytics';
import GoogleAnalytics from './GoogleAnalytics';

const Root = () => {

  useEffect(() => {
    // Replace 'UA-XXXXXX-X' with your Google Analytics tracking ID
    initGA('G-M0F4C1BVVW');
    // Track the initial page view
    PageView();
  }, []);
  return (

      <BrowserRouter>
      <div>
        <GoogleAnalytics trackingId="G-M0F4C1BVVW" />
        {/* The rest of your app goes here */}
      </div>
      <Routes>
        <Route exact path="/" element={<HomeV1 />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/service" element={<ServiceV1 />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/portfolio" element={<PortfolioFull />} />
        <Route path="/portfolio/:slug" element={<PortfolioDetails />} />
        <Route path="/blog-list" element={<BlogList />} />
        <Route path="/blog-details" element={<BlogListDetails />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<Root />, document.getElementById('vpixelsolutions'));
