import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Portfolio from './section-components/portfolio-full';
import Cta from './section-components/cta-v11';
import Footer from './global-components/footer-v2';

export const PortfolioFull = () => {
    return <div>
      <Helmet>
        <title>VPixelSolutions | Let’s See Our Most Recent Work</title>
        <meta name="description" content="Explore our comprehensive web development services at VPixelSolutions. From custom website design to advanced SEO strategies, we provide solutions to enhance and grow your digital presence. Discover how we can help your business today." />
        {/* meta */}
      </Helmet>
        <Navbar />
        <PageHeader headertitle="Portfolio Full Width"  />
        <Portfolio />
        <Cta />
        <Footer />
    </div>
}

