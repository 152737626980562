import React, { useState } from 'react';

const Contact = () => {
	// Function for form submission
	// Initializing state for form data and loading status
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		subject: '',
		message: '',
		host: 'VPixelSolutions',
	});

	const myUrlApi = process.env.REACT_APP_PUBLIC_URL_API;

	// Handler for input field changes to update form data state
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	// Function to handle form submission
	const onSubmit = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		setLoading(true); // Indicate loading state
		// Prepare form data for API submission
		const json = JSON.stringify(formData);

		try {
			// API call to send email with form data
			const response = await fetch(`${myUrlApi}/api/send-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: json,
			});

			const resData = await response.json();

			if (response.ok) {
				// Handle successful email send (e.g., reset form, show success message)
				setFormData({ name: "", email: "", subject: "", message: "", host: "VPixelSolutions" });
			} else {
				// Handle API errors (e.g., show error message)
				console.error("Failed to send email:", resData);
			}
		} catch (error) {
			// Catch and handle any errors in the fetch operation
			console.error("Error sending email:", error);
		} finally {
			setLoading(false); // Reset loading state regardless of request outcome
		}
	};

	return (
		<div>
			<section className="contact-two">
				<div className="container">
					<div className="row">
						<div className="col-lg-5">
							<div className="contact-two__content">
								<div className="contact-two__block">
									<h3>Don't Hesitate To Contact Us</h3>
									<p>Sed ut perspiciatis unde omnis iste natus erro voluptat accusantium doloremque laudantium totam rem aperiam eaque</p>
								</div>
								<ul className="list-unstyled contact-two__info-list">
									<li>
										<div className="contact-two__info-list-title">
											<i className="far fa-envelope" />
											Email Us :
										</div>
										<p><a href="mailto:atomyuniverse.com@gmail.com">atomyuniverse.com@gmail.com</a></p>
									</li>
									<li>
										<div className="contact-two__info-list-title">
											<i className="far fa-phone" />
											Telegram :
										</div>{/* /.contact-two__info-list-title */}
										<p><a href="https://t.me/VPixelSolutions">VPixelSolutions</a></p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-7">
							<form onSubmit={onSubmit} className="contact-two__form">
								{/* Form fields and structure */}
								<div className="row gx-3 gy-4">
									<div className="col-md-6">
										<input
											name="name"
											placeholder="Full Name *"
											className="form-control"
											type="text"
											required
											onChange={handleInputChange}
											value={formData.name}
										/>
									</div>
									<div className="col-md-6">
										<input
											name="email"
											placeholder="Email Address *"
											className="form-control"
											type="email"
											required
											onChange={handleInputChange}
											value={formData.email}
										/>
									</div>
									<div className="col-12">
										<input
											name="subject"
											placeholder="Subject *"
											className="form-control"
											type="text"
											onChange={handleInputChange}
											value={formData.subject}
											required
										/>
									</div>
									<div className="col-md-12">
									  <textarea
										  name="message"
										  placeholder="Message *"
										  rows={6}
										  className="form-control"
										  required
										  onChange={handleInputChange}
										  value={formData.message}
									  />
									</div>
									<div className="col-md-12">
										<button type="submit" className="thm-btn contact-two__btn">
											{loading ? "Sending..." : "Send Message"} <i className="fa fa-angle-double-right" />
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			{/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd" className="google-map__contact" allowFullScreen></iframe>*/}
		</div>
	);
};

export default Contact;
