import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
// import Brand from './section-components/brand-one';
import WhyChooseUs from './section-components/why-choose-us';
import AboutStyleOne from './section-components/about-style-one';
import AboutStyleTwo from './section-components/about-style-two';
// import Team from './section-components/team';
import Skill from './section-components/skill';
import Portfolio from './section-components/portfolio-style-one';
import Footer from './global-components/footer';

export const HomeV1 = () => {
    return <div>
      <Helmet>
        <title>VPixelSolutions | Discover New Idea To Build Your Business Development</title>
        <meta name="description" content="Explore our comprehensive web development services at VPixelSolutions. From custom website design to advanced SEO strategies, we provide solutions to enhance and grow your digital presence. Discover how we can help your business today." />
        {/* meta */}
      </Helmet>
        <Navbar />
        <Banner />
        {/*BannerΩ*/}
        <WhyChooseUs />
        <AboutStyleOne />
        <AboutStyleTwo />
        <Skill />
        <Portfolio />
        <Footer />
    </div>
}

