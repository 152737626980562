import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import PortfolioDetailsPage from './section-components/portfolio-details';
import Cta from './section-components/cta-v10';
import Footer from './global-components/footer-v2';

export const PortfolioDetails = () => {
    const [childData, setChildData] = useState('');

    const handleChildData = (data) => {
        setChildData(data);
    };

    return (
        <div>
            <Helmet>
                <title>VPixelSolutions | Portfolio {childData}</title>
                <meta name="description" content="Explore our comprehensive web development services at VPixelSolutions. From custom website design to advanced SEO strategies, we provide solutions to enhance and grow your digital presence. Discover how we can help your business today." />
                {/* meta */}
            </Helmet>
            <Navbar />
            <PageHeader headertitle={childData}  />
            <PortfolioDetailsPage onChildDataChange={handleChildData} />
            <Cta />
            <Footer />
        </div>
    );
}
