// In src/api/projectsApi.js
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_PUBLIC_URL_API + '/api'; // Adjust this URL to your Django server's address

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchProjects = async () => {
  let token = localStorage.getItem('jwtToken');

  // Setup headers
  let headers = {
    'Content-Type': 'application/json',
    ...(token && {'Authorization': `Bearer ${token}`}),
  };

  try {
    // Try to fetch projects with the current token
    const response = await axiosInstance.get('/projects/', {headers});

    return response.data; // Return the projects data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Unauthorized, token might be invalid or expired
      console.log("Token is invalid or expired. Fetching a new token...");

      try {
        // Fetch a new token
        const tokenResponse = await axiosInstance.get('/get_token');
        token = tokenResponse.data.token;
        localStorage.setItem('jwtToken', token); // Save the new token for future requests

        // Retry the original request with the new token
        headers['Authorization'] = `Bearer ${token}`; // Update headers with the new token
        const retryResponse = await axiosInstance.get('/projects/', {headers});
        return retryResponse.data; // Return the projects data
      } catch (retryError) {
        console.error('Error during token fetching or request retry:', retryError);
        throw retryError; // Rethrow or handle error as needed
      }
    } else {
      // Other errors
      console.error('Error fetching projects:', error);
      throw error; // Rethrow or handle error as needed
    }

    // eslint-disable-next-line no-unreachable
  }
};


export const fetchProjectDetails = async (slug) => {
  let token = localStorage.getItem('jwtToken');

  // Setup headers
  let headers = {
    'Content-Type': 'application/json',
    ...(token && {'Authorization': `Bearer ${token}`}),
  };

  try {
    // Try to fetch projects with the current token
    const response = await axiosInstance.get(`${BASE_URL}/projects/${slug}/`, {headers});
    return response.data; // Return the projects data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Unauthorized, token might be invalid or expired
      console.log("Token is invalid or expired. Fetching a new token...");

      try {
        // Fetch a new token
        const tokenResponse = await axiosInstance.get('/get_token');
        token = tokenResponse.data.token;
        localStorage.setItem('jwtToken', token); // Save the new token for future requests

        // Retry the original request with the new token
        headers['Authorization'] = `Bearer ${token}`; // Update headers with the new token
        const retryResponse = await axiosInstance.get('/projects/', {headers});
        return retryResponse.data; // Return the projects data
      } catch (retryError) {
        console.error('Error during token fetching or request retry:', retryError);
        throw retryError; // Rethrow or handle error as needed
      }
    } else {
      // Other errors
      console.error('Error fetching projects:', error);
      throw error; // Rethrow or handle error as needed
    }

    // eslint-disable-next-line no-unreachable
  }
};


// export const fetchProjectDetails = (slug) => {
//   return axios.get(`${BASE_URL}/projects/${slug}/`);
// };
